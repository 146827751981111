import React from 'react'
import VerficationComponent from '../../components/signInComponent/verfication/VerficationComponent'

const VerficatioPage = () => {
  return (
    <div>
        
        <VerficationComponent/>
    </div>
  )
}

export default VerficatioPage
import React from 'react'
import LoginComponents from '../../components/loginComponent/LoginComponents'
const LoginPage = () => {
  return (
    <div>
        <LoginComponents/>
    </div>
  )
}

export default LoginPage
import React from 'react'
import ChangePassword from '../../components/loginComponent/changepassword/ChangePassword'
const ChangePasswordPage = () => {
  return (
    <div>
        <ChangePassword/>
    </div>
  )
}

export default ChangePasswordPage
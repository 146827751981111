import React from 'react'
import SignUpComponent from '../../components/signInComponent/SignUpComponent'
const SignUpPage = () => {
  return (
    <div>
        <SignUpComponent/>
    </div>
  )
}

export default SignUpPage